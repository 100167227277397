/* global DYNAMIC_YIELD_DYNAMIC_URL */
/* global DYNAMIC_YIELD_STATIC_URL */

import React from 'react';
import Head from 'next/head';
import { HomepageApp } from 'components/homepage/HomeApp/HomeApp';
import metadata from 'shared/constants/meta-tags';
import { Provider } from 'react-redux';
import store from 'redux/store';

const HomePage = () => (
  <>
    <Head>
      <title>{metadata.home.title}</title>
      <meta property="og:title" content={metadata.home.title} key="title" />
      <meta name="description" content={metadata.home.description} key="description" />
      <script
        id="dynamic-yield-script"
        type="text/javascript"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.DY = window.DY || {};
            DY.recommendationContext = { type: 'HOMEPAGE' };
            function readCookie(name) {
                  var nameEQ = name + '=';
                  var ca = document.cookie.split(';');
                  var c;
        
                  for (var i = 0; i < ca.length; i += 1) {
                    c = ca[i];
                    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
                  }
                  return null;
                }
                function readConsentCookie(){
                  const optanonCookie = readCookie('OptanonConsent')
                  if (optanonCookie){
                    var consentGroups = optanonCookie.split('&').find( x => x.startsWith('groups=')).replace('groups=','').split('%2C')
                    return consentGroups.reduce((prev, curr) => {
                      var cookie = curr.split('%3A'); return {...prev, ...{[cookie[0]]: cookie[1]}}
                    }, new Map())
                  }
                }
                function isOneTrustEnabled(category) {
                  const COOKIE_GROUP_VALUE  = readConsentCookie();
                  switch(category) {
                    case 'C0001':
                    case 'C0003':
                    // Necessary and Functional are now Always Active
                      return true;
                    case 'C0002':
                    case 'C0004':
                      // Both Targeting and Perfomance cookies need to be Active in order to enable consent
                      return !!COOKIE_GROUP_VALUE && (COOKIE_GROUP_VALUE['C0002'] == '1' && COOKIE_GROUP_VALUE['C0004'] == '1')
                    default:
                      // If the destination doesn't have a specific category, then consent is disabled.
                      return false;
                  }
                }
                function dynamicYeildSetActiveConsent() {
                  if (window.DY) {
                    const isEnabled = isOneTrustEnabled('C0002')
                    window.DY.userActiveConsent = { accepted: isEnabled }
                  }
                }
                dynamicYeildSetActiveConsent();
          `
        }}
      />
      <script type="text/javascript" src={`${DYNAMIC_YIELD_DYNAMIC_URL}`} />
      <script type="text/javascript" src={`${DYNAMIC_YIELD_STATIC_URL}`} />
    </Head>
    <Provider store={store}>
      <HomepageApp />
    </Provider>
  </>
);

export default HomePage;
